import { defineAsyncComponent } from 'vue'
export default {
  auth: defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/auth.vue").then(m => m.default || m)),
  "base-layout-with-sidebar": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/base-layout-with-sidebar.vue").then(m => m.default || m)),
  "contribution-flow": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/contribution-flow.vue").then(m => m.default || m)),
  "main-layout-gray-background": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/main-layout-gray-background.vue").then(m => m.default || m)),
  "main-layout": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/main-layout.vue").then(m => m.default || m)),
  onboarding: defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/onboarding.vue").then(m => m.default || m)),
  "sign-up-and-pledge": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/sign-up-and-pledge.vue").then(m => m.default || m)),
  "simple-layout": defineAsyncComponent(() => import("/opt/build/repo/packages/member-webapp/layouts/simple-layout.vue").then(m => m.default || m))
}