import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45maintenance_45redirect_45global from "/opt/build/repo/packages/member-webapp/middleware/01.maintenance-redirect.global.ts";
import _02_45permission_45redirects_45global from "/opt/build/repo/packages/member-webapp/middleware/02.permission-redirects.global.ts";
import _03_45auth_45check_45global from "/opt/build/repo/packages/member-webapp/middleware/03.auth-check.global.ts";
import track_45page_45views_45global from "/opt/build/repo/packages/member-webapp/middleware/track-page-views.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45maintenance_45redirect_45global,
  _02_45permission_45redirects_45global,
  _03_45auth_45check_45global,
  track_45page_45views_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "contribution-form-redirect": () => import("/opt/build/repo/packages/member-webapp/middleware/contribution-form-redirect.ts"),
  "grant-flow-redirect": () => import("/opt/build/repo/packages/member-webapp/middleware/grant-flow-redirect.ts"),
  "password-change-redirect": () => import("/opt/build/repo/packages/member-webapp/middleware/password-change-redirect.ts"),
  "pledge-new-redirect": () => import("/opt/build/repo/packages/member-webapp/middleware/pledge-new-redirect.ts"),
  "transfer-your-daf-redirect": () => import("/opt/build/repo/packages/member-webapp/middleware/transfer-your-daf-redirect.ts"),
  auth: () => import("/opt/build/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}