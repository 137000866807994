import unhead_neSs9z3UJp from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/packages/member-webapp/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/build/repo/packages/member-webapp/.nuxt/floating-vue.mjs";
import formkitPlugin_pZqjah0RUG from "/opt/build/repo/packages/member-webapp/.nuxt/formkitPlugin.mjs";
import plugin_jXOoAsp0hm from "/opt/build/repo/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import cookie_consent_client_xnDplH3AlY from "/opt/build/repo/packages/member-webapp/plugins/cookie-consent.client.ts";
import error_client_aUA8HQSx65 from "/opt/build/repo/packages/member-webapp/plugins/error.client.ts";
import fontawesome_klhsrycjcK from "/opt/build/repo/packages/member-webapp/plugins/fontawesome.js";
import global_error_hander_68ZmAiNcej from "/opt/build/repo/packages/member-webapp/plugins/global-error-hander.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/packages/member-webapp/plugins/sentry.client.ts";
import simple_analytics_client_gNiX1sibsq from "/opt/build/repo/packages/member-webapp/plugins/simple-analytics.client.js";
import website_url_helpers_hUJmNmTVYg from "/opt/build/repo/packages/member-webapp/plugins/website-url-helpers.ts";
export default [
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  floating_vue_EIcJ7xiw0h,
  formkitPlugin_pZqjah0RUG,
  plugin_jXOoAsp0hm,
  cookie_consent_client_xnDplH3AlY,
  error_client_aUA8HQSx65,
  fontawesome_klhsrycjcK,
  global_error_hander_68ZmAiNcej,
  sentry_client_shVUlIjFLk,
  simple_analytics_client_gNiX1sibsq,
  website_url_helpers_hUJmNmTVYg
]